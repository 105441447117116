import React from 'react';
import styles from './ticker.module.css';
import AnimatedNumber from 'animated-number-react';
import CoreButton from '../Button';

const numbersFormatter = (num, digits) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(({ value }) => num >= value);

  return item
    ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : '0';
};

const formatValue3 = (value) => value.toFixed(3);
const formatValue2 = (value) => value.toFixed(2);
const formatValue1 = (value) => value.toFixed(1);

const getRioCirculationData = async () => {
  try {
    const res = await fetch(process.env.REACT_APP_RIO_CIRCULATING_SUPPLY_URL);
    const data = await res.json();
    return {
      circulating: Math.round(parseFloat(data)),
    };
  } catch (e) {
    return { holders: 0 };
  }
};

const getEthereumData = async () => {
  try {
    const res = await fetch(process.env.REACT_APP_RIO_ETHEREUM_DATA_URL);
    const data = await res.json();
    return {
      price: data.tokenInfo.price.rate,
      percentChange: data.tokenInfo.price.diff,
      volume: data.tokenInfo.price.volume24h,
      holders: data.tokenInfo.holdersCount,
    };
  } catch (e) {
    // TODO: add info toast
    return { price: 0, volume: 0, percentChange: 0, holders: 0 };
  }
};

const getStellarData = async () => {
  try {
    const res = await fetch(process.env.REACT_APP_RIO_STELLAR_HOLDERS_URL);
    const data = await res.json();

    const numAccounts = data._embedded.records[0].num_accounts;

    return {
      holders: numAccounts,
    };
  } catch (e) {
    // TODO: add info toast
    return { holders: 0 };
  }
};

const getAlgorandData = async () => {
  try {
    const res = await fetch(process.env.REACT_APP_RIO_ALGORAND_HOLDERS_URL);
    const data = await res.json();
    return {
      holders: data.balances.length,
    };
  } catch (e) {
    // TODO: add info toast
    return { holders: 0 };
  }
};

const getInitialState = () => ({
  circulating: 0,
  holders: { ethereum: 0, stellar: 0, algorand: 0 },
  percentChange: 0,
  price: 0,
  volume: 0,
  menuOpen: false,
});

export class Ticker extends React.Component {
  constructor(props) {
    super(props);

    this.state = getInitialState();

    this.pollAndUpdateRioStatsData();
    setInterval(this.pollAndUpdateRioStatsData, 30000);
  }

  pollAndUpdateRioStatsData = async () => {
    const [rioData, ethereumData, stellarData, algorandData] =
      await Promise.all([
        getRioCirculationData(),
        getEthereumData(),
        getStellarData(),
        getAlgorandData(),
      ]);

    this.setState({
      circulating: rioData.circulating,
      holders: {
        ethereum: ethereumData.holders,
        stellar: stellarData.holders,
        algorand: algorandData.holders,
      },
      percentChange: ethereumData.percentChange,
      price: ethereumData.price,
      volume: ethereumData.volume,
    });
  };

  render() {
    const holders = tallyHolders(this.state.holders);
    return (
      <>
        <div
          className={styles['mobile-menu']}
          onClick={() => this.setState({ menuOpen: true })}
        >
          <svg
            width="24"
            height="16"
            viewBox="0 0 24 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.800003 2.00005C0.800003 1.68179 0.968574 1.37656 1.26863 1.15152C1.56869 0.926477 1.97566 0.800049 2.4 0.800049H21.6C22.0244 0.800049 22.4313 0.926477 22.7314 1.15152C23.0314 1.37656 23.2 1.68179 23.2 2.00005C23.2 2.31831 23.0314 2.62353 22.7314 2.84858C22.4313 3.07362 22.0244 3.20005 21.6 3.20005H2.4C1.97566 3.20005 1.56869 3.07362 1.26863 2.84858C0.968574 2.62353 0.800003 2.31831 0.800003 2.00005ZM0.800003 8.00005C0.800003 7.68179 0.968574 7.37656 1.26863 7.15152C1.56869 6.92648 1.97566 6.80005 2.4 6.80005H21.6C22.0244 6.80005 22.4313 6.92648 22.7314 7.15152C23.0314 7.37656 23.2 7.68179 23.2 8.00005C23.2 8.31831 23.0314 8.62353 22.7314 8.84858C22.4313 9.07362 22.0244 9.20005 21.6 9.20005H2.4C1.97566 9.20005 1.56869 9.07362 1.26863 8.84858C0.968574 8.62353 0.800003 8.31831 0.800003 8.00005ZM0.800003 14C0.800003 13.6818 0.968574 13.3766 1.26863 13.1515C1.56869 12.9265 1.97566 12.8 2.4 12.8H21.6C22.0244 12.8 22.4313 12.9265 22.7314 13.1515C23.0314 13.3766 23.2 13.6818 23.2 14C23.2 14.3183 23.0314 14.6235 22.7314 14.8486C22.4313 15.0736 22.0244 15.2 21.6 15.2H2.4C1.97566 15.2 1.56869 15.0736 1.26863 14.8486C0.968574 14.6235 0.800003 14.3183 0.800003 14Z"
              fill="white"
            />
          </svg>
        </div>
        <div
          className={`${styles.ticker} ${this.state.menuOpen && styles.open}`}
        >
          <div
            className={styles['close-menu']}
            onClick={() => this.setState({ menuOpen: false })}
          >
            <svg
              width="37"
              height="27"
              viewBox="0 0 37 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 11.5C3.17157 11.5 2.5 12.1716 2.5 13C2.5 13.8284 3.17157 14.5 4 14.5L4 11.5ZM34.0607 14.0607C34.6464 13.4749 34.6464 12.5251 34.0607 11.9393L24.5147 2.3934C23.9289 1.80761 22.9792 1.80761 22.3934 2.3934C21.8076 2.97919 21.8076 3.92893 22.3934 4.51472L30.8787 13L22.3934 21.4853C21.8076 22.0711 21.8076 23.0208 22.3934 23.6066C22.9792 24.1924 23.9289 24.1924 24.5147 23.6066L34.0607 14.0607ZM4 14.5L33 14.5V11.5L4 11.5L4 14.5Z"
                fill="white"
              />
            </svg>
          </div>

          <div className={styles.holder}>
            <div className={styles.col}>
              <span>Rio Price</span>
              <div>
                $
                <AnimatedNumber
                  value={this.state.price}
                  formatValue={formatValue3}
                />
                <span
                  className={`${styles.percent} ${
                    this.state.percentChange >= 0
                      ? styles.positive
                      : styles.negative
                  }`}
                >
                  {this.state.percentChange > 0 && '+'}
                  <AnimatedNumber
                    value={this.state.percentChange}
                    formatValue={formatValue3}
                  />
                  %
                </span>
              </div>
            </div>
            <div className={styles.col}>
              <span>MKt. Cap</span>
              <div>
                $
                <AnimatedNumber
                  value={numbersFormatter(
                    this.state.price * this.state.circulating,
                    2
                  )}
                  formatValue={formatValue2}
                />
                {String(
                  numbersFormatter(this.state.price * this.state.circulating, 2)
                ).substr(-1)}
              </div>
            </div>
            <div className={styles.col}>
              <span>24h Volume</span>
              <div>
                <AnimatedNumber
                  value={numbersFormatter(this.state.volume, 2)}
                  formatValue={formatValue2}
                />
                {String(numbersFormatter(this.state.volume, 2)).substr(-1)}
              </div>
            </div>
            <div className={styles.col}>
              <span>Holders</span>
              <div>
                <AnimatedNumber
                  value={numbersFormatter(holders, 2)}
                  formatValue={formatValue1}
                />
                {String(numbersFormatter(holders, 2)).substr(-1)}
              </div>
            </div>
            <div className={styles.col}>
              <CoreButton
                modifier={'wallet'}
                onClick={() => this.props.setIsModalOpen(true)}
              >
                ADD NETWORK TO WALLET
              </CoreButton>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const tallyHolders = (holders) => {
  return Object.keys(holders).reduce((acc, key) => {
    if (!holders.hasOwnProperty(key)) return acc;
    return acc + holders[key];
  }, 0);
};
