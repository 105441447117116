import React from 'react';
import axios from 'axios';
import XMLParser from 'react-xml-parser';

import styles from './footer.module.css';

import { Carousel } from 'react-bootstrap';

import { LearningCardGroup } from './LearningCardGroup';
import CoreButton from '../Button';

export class LandingLearningSummary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      renderCarousel: false,
      carouselItems: [],
    };
  }

  componentDidMount() {
    axios
      .get(process.env.REACT_APP_REALIO_BLOG_RSS_FEED_URL, {
        headers: { 'Content-Type': 'application/xml; charset=utf-8' },
      })
      .then(parseRssFeed)
      .then((items) => {
        this.setState({
          renderCarousel: true,
          carouselItems: items,
        });
      })
      .catch((error) => {
        console.error({ error });
      });
  }

  render() {
    return (
      <div className={styles.boxes}>
        <LearningCardGroup learnings={getNetworkLearnings()} />
        <div className={`${styles.box} ${styles.blog}`}>
          <span>Realio Blog</span>
          <Carousel className={styles['blog-holder']}>
            {this.state.renderCarousel &&
              makeCarouselItems(this.state.carouselItems)}
          </Carousel>
        </div>
      </div>
    );
  }
}

const getNetworkLearnings = () => [
  makeLearningProps(
    'Realio.fund',
    'Eliminating barriers to invest-in, trade, and leverage private, real-world assets through decentralized finance (“DeFi”).',
    'https://realio.fund'
  ),
  makeLearningProps(
    'Districts',
    'The base layer for a real-world themed immersive experience unifying and interconnecting physical and virtual realities.',
    'https://districts.xyz/'
  ),
  makeLearningProps(
    'Freehold Wallet',
    "A non-custodial, multi-chain DeFi wallet app built on Realio's blockchain infrastructure.",
    'https://twitter.com/freehold_wallet'
  ),
  makeLearningProps(
    '$RIO',
    'The native currency of the Realio Network.',
    'https://app.uniswap.org/#/swap?inputCurrency=0xf21661d0d1d76d3ecb8e1b9f1c923dbfffae4097&chain=mainnet'
  ),
  makeLearningProps(
    '$RST',
    'A hybrid equity token that represent ownership of the Realio Ecosystem and provides utility functions on the Network.',
    'https://www.realio.fund/rst'
  ),
  makeLearningProps(
    '$LMX',
    'A tokenized fund specializing in low-cost Bitcoin production.',
    'https://thetokenizer.io/2020/11/06/tokenized-fund-specializing-in-low-cost-bitcoin-production/'
  ),
];

const makeLearningProps = (title, text, linkPath) => ({
  title,
  text,
  linkPath,
});

const parseRssFeed = ({ data }) => {
  const parser = new XMLParser();
  const parsedString = parser.parseFromString(data);
  const items = parsedString.children[0].getElementsByTagName('item');

  return items.map((item) => {
    const title = item.getElementsByTagName('title')[0].value;
    const descriptionElement = htmlDecode(
      item.getElementsByTagName('description')[0].value
    );
    const creator = item.getElementsByTagName('dc:creator')[0].value;
    const date = item.getElementsByTagName('pubDate')[0].value;
    const anchorElement = descriptionElement
      .getElementsByTagName('body')[0]
      .getElementsByTagName('a')[0];
    const link = anchorElement.getAttribute('href');
    const imgElement = anchorElement.getElementsByTagName('img')[0];
    const imgSrc = imgElement.getAttribute('src');

    return { imgSrc, title, link, creator, date };
  });
};

const htmlDecode = (input) => {
  const e = document.createElement('div');
  e.innerHTML = input;

  return new DOMParser().parseFromString(
    e.childNodes[0].nodeValue,
    'text/html'
  );
};

const makeCarouselItems = (items) => {
  return items.map((item, index) => {
    if (index < 6) {
      return (
        <Carousel.Item key={index} onClick={() => window.open(item.link)}>
          <h5>{item.title}</h5>
          <img src={item.imgSrc} alt={item.title} />
          <CoreButton modifier={'invisible'}>Read Now</CoreButton>
        </Carousel.Item>
      );
    }
  });
};
