import styles from './realioverse.module.css';

const RealioVerse = () => (
  <div className={styles.realioverse}>
    <div>
      <h4>
        Dream. Design. <br />
        Districts.
      </h4>
      <h5>
        Districts is a virtual world mirroring Earth, offering an innovative
        avenue for everyone to own, build, and earn from virtual land while
        shaping the cities of the future.
      </h5>
      <div className={styles.flex}>
        <p>
          We will provide creative professionals and real estate enthusiasts
          with cutting-edge tools and collaborative spaces to push the
          boundaries of digital design. Through the power of decentralized
          technology combined with our unique 'Land Bank' mechanism, we will
          create new investment opportunities, fostering a dynamic digital
          economy.
        </p>
        <p>
          Moreover, beyond just digital creation, Districts will integrate a
          profound commitment to inclusive impact, with select land sales
          proceeds channeling support to global nonprofits, thus blending
          innovation with impactful real-world change.
          <span>
            Visit{' '}
            <a href="https://districts.xyz/" rel="noreferrer" target="_blank">
              Districts
            </a>{' '}
            today
          </span>
        </p>
      </div>
    </div>
  </div>
);

export default RealioVerse;
