import React from 'react';
import styles from './features.module.css';
import Evm from '../../images/evm.png';

export class LandingNetworkFeatures extends React.Component {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.card}>
          <div className={styles.images}>
            <img
              src="https://cdn.realio.fund/static/assets/images/minting-coins.png"
              alt="tokenization"
            />
          </div>
          <div className={styles.text}>
            <h6>RWA Tokenization</h6>
            <p className={styles.text}>
              The Realio Network allows for the creation of new assets in a
              secure decentralized process.
            </p>
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.images}>
            <img
              src="https://cdn.realio.fund/static/assets/images/security-lock.png"
              alt="security lock"
              style={{ width: '42px' }}
            />
          </div>
          <div className={styles.text}>
            <h6>Security</h6>
            <p className={styles.text}>
              The blockchain is secured by validators bonding Native $RIO or
              $RST in the Network.
            </p>
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.images}>
            <img
              src="https://cdn.realio.fund/static/assets/images/web3-logo.png"
              alt="web 3"
            />
          </div>
          <div className={styles.text}>
            <h6>Web3 Compliant</h6>
            <p className={styles.text}>
              The Network intends to host the base layer for a real-world-based
              metaverse, Districts.
            </p>
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.images}>
            <img
              src="https://cdn.realio.fund/static/assets/images/governance-gavel.png"
              alt="governance"
              style={{ width: '70px' }}
            />
          </div>
          <div className={styles.text}>
            <h6>Governance</h6>
            <p className={styles.text}>
              Built for its users, the Realio Network allows participation and
              voting in network upgrades when a user participates as a validator
              or a delegator to validators.
            </p>
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.images}>
            <img src={Evm} alt="key" />
          </div>
          <div className={styles.text}>
            <h6>EVM Interoperability</h6>
            <p className={styles.text}>
              The Network is a multi-chain ecosystem focused on the issuance and
              management of RWAs across many non-EVM and EVM-compatible chains.
              As a Cosmos-based chain, it leverages the IBC protocol to achieve
              interoperability with blockchains.
            </p>
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.images}>
            <img
              src="https://cdn.realio.fund/static/assets/images/staking-vault.png"
              alt="staking"
            />
          </div>
          <div className={styles.text}>
            <h6>Staking</h6>
            <p className={styles.text}>
              Validators and delegators who help secure the Realio Network by
              bonding $RIO or $RST earn their share of block rewards based on
              their contribution amount.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
