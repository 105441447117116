import React from 'react';
import styles from './footer.module.css';

export class LearningCardGroup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      learnings: props.learnings || [],
    };
  }

  render() {
    return (
      <div className={styles['holder']}>
        {this.state.learnings.map(renderLearningCardCol)}
      </div>
    );
  }
}

const renderLearningCardCol = (props) => (
  <div
    className={styles.box}
    onClick={() => props.linkPath && window.open(props.linkPath)}
  >
    <div className={styles.title}>{props.title}</div>
    <div className={styles.content}>{props.text}</div>
  </div>
);
