import { useEffect, useState } from 'react';

const ErrorNotification = ({ error, duration, success }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);

    const timeout = setTimeout(() => {
      setIsVisible(false);
    }, duration);

    return () => {
      clearTimeout(timeout);
    };
  }, [error]);

  const notificationStyle = {
    position: 'fixed',
    bottom: '20px',
    left: isVisible ? '20px' : '-300px',
    width: '300px',
    backgroundColor: success ? 'green' : 'red',
    borderRadius: '8px',
    padding: '24px',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: `left 500ms ease-in-out`,
    zIndex: '10000',
  };

  return <div style={{ ...notificationStyle }}>{error}</div>;
};

export default ErrorNotification;
