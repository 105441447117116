import React, { useEffect, useState } from 'react';
import detectEthereumProvider from '@metamask/detect-provider';
import styles from './landing.module.css';
import { LandingNetworkFeatures } from '../../components/Features';
import { Ticker } from '../../components/Ticker';
import Logo from '../../components/Logo';
import CoreButton from '../../components/Button';
import Explore from '../../components/Explore';
import Roadmap from '../../components/Roadmap';
import Footer from '../../components/Footer';
import RealioVerse from '../../components/Realioverse';
import MetaMask from '../../images/meta.svg';
import Keplr from '../../images/keplr.svg';
import Close from '../../images/close.svg';
import Vid from '../../videos/bg.mp4';
import ErrorNotification from '../../components/Error';

export const LandingPage = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState();

  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  useEffect(() => {
    // adding the event when scroll change Logo
    window.addEventListener('scroll', changeBackground);
  });

  const connectToMetaMask = async () => {
    if (typeof window.ethereum !== 'undefined') {
      console.log('Metamask is installed');
    } else {
      console.log('Metamask is not installed');
      setError('Metamask is not installed');
    }

    try {
      const provider = await detectEthereumProvider();
      if (provider) {
        const chainId = await provider.request({ method: 'eth_chainId' });
        if (chainId === process.env.REACT_APP_CHAIN_ID_HEX) {
          setSuccess('Realio has been added to your MetaMask wallet');
        }
      }
    } catch (err) {
      console.log(err);
    }

    try {
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: process.env.REACT_APP_CHAIN_ID_HEX,
            rpcUrls: [process.env.REACT_APP_RPC_URL],
            chainName: 'realio',
            nativeCurrency: {
              name: 'ario',
              symbol: 'RIO',
              decimals: 18,
            },
            blockExplorerUrls: [process.env.REACT_APP_BLOCK_EXPLORE_URL],
          },
        ],
      });
    } catch (error) {
      console.log(error);
      setError('Error connecting to MetaMask');
    }
  };

  const connectToKeplr = async () => {
    if (!window.keplr) {
      console.error('Keplr extension not installed');
      setError('Keplr extension not installed');
      return;
    }
    try {
      await window.keplr.enable('realionetwork_3301-1');
      const chainId = 'realionetwork_3301-1';
      window.getOfflineSigner(chainId);
      setSuccess('Realio has been added to your Keplr wallet');
    } catch (err) {
      console.log(err);
    }

    try {
      await window.keplr.experimentalSuggestChain({
        chainId: process.env.REACT_APP_CHAIN_ID,
        chainName: 'realio',
        rpc: process.env.REACT_APP_RPC,
        rest: process.env.REACT_APP_REST,
        bip44: {
          coinType: 60,
        },
        bech32Config: {
          bech32PrefixAccAddr: 'realio',
          bech32PrefixAccPub: 'realio' + 'pub',
          bech32PrefixValAddr: 'realio' + 'valoper',
          bech32PrefixValPub: 'realio' + 'valoperpub',
          bech32PrefixConsAddr: 'realio' + 'valcons',
          bech32PrefixConsPub: 'realio' + 'valconspub',
        },
        currencies: [
          {
            coinDenom: 'RIO',
            coinMinimalDenom: 'ario',
            coinDecimals: 18,
          },
          {
            coinDenom: 'RST',
            coinMinimalDenom: 'arst',
            coinDecimals: 18,
          },
        ],
        feeCurrencies: [
          {
            coinDenom: 'RIO',
            coinMinimalDenom: 'ario',
            coinDecimals: 18,
            gasPriceStep: {
              low: 7.0,
              average: 8.523,
              high: 10.404,
            },
          },
        ],
        stakeCurrency: {
          coinDenom: 'RIO',
          coinMinimalDenom: 'ario',
          coinDecimals: 18,
        },

        features: ['ibc-transfer', 'ibc-go', 'eth-address-gen', 'eth-key-sign'],
      });
    } catch (error) {
      setError('Failed to connect to Keplr wallet');
      console.error('Failed to connect to Keplr wallet:', error);
    }
  };

  return (
    <>
      <div className={styles.background}>
        <video playsInline autoPlay loop muted src={Vid}></video>
      </div>
      <div className={styles.bgOverlay} />
      <header className={`${styles.header} ${scrolled && styles['header-bg']}`}>
        <Logo />
        <Ticker setIsModalOpen={setIsModalOpen} />
      </header>
      <div className={styles.overlay}>
        <div className={styles.wrapper}>
          <div className={styles.hero}>
            <div>
              <h1>Web3 Ecosystem for Digital & Real-World Assets</h1>
              <div className={styles.signup}>
                <CoreButton
                  onClick={() => window.open('https://app.realio.network')}
                >
                  Launch app
                </CoreButton>
              </div>
            </div>
          </div>
          <div className={styles.features}>
            <h3 className={styles.schedule}>Realio Network Features</h3>
            <h5>
              An interoperable Layer-1 multi-chain Web3 ecosystem focused on the
              issuance and management of digitally native real-world assets
              (RWAs). Its design is fully open-source, permissionless, and
              purpose-built.
            </h5>
            <LandingNetworkFeatures />
          </div>
          <Explore />
          <Roadmap />
          <RealioVerse />
        </div>

        <Footer />
        {error && <ErrorNotification error={error} duration={3000} />}
        {success && (
          <ErrorNotification error={success} duration={3000} success={true} />
        )}
        {isModalOpen && (
          <>
            <div
              className={styles.modalOverlay}
              onClick={() => setIsModalOpen(false)}
            />
            <div className={styles.modal}>
              <div>
                <h3>Add Realio Network to your wallet</h3>
                <div className={styles.walletItem}>
                  <div>
                    <img src={Keplr} alt="Keplr" />
                    Keplr
                  </div>
                  <CoreButton
                    modifier={'wallet'}
                    onClick={() => connectToKeplr()}
                  >
                    ADD
                  </CoreButton>
                </div>
                <div className={styles.walletItem}>
                  <div>
                    <img src={MetaMask} alt="MetaMask" />
                    MetaMask
                  </div>
                  <CoreButton
                    modifier={'wallet'}
                    onClick={() => connectToMetaMask()}
                  >
                    ADD
                  </CoreButton>
                </div>
              </div>
              <button
                onClick={() => setIsModalOpen(false)}
                className={styles.close}
              >
                <img src={Close} alt="close" />
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};
