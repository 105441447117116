import styles from './roadmap.module.css';
import EmissionsImage from '../../images/image.png';
import { ReactComponent as Star } from "../../images/star.svg";

const Roadmap = () => (
  <div className={styles.roadmap}>
    <h2>RIO, the Realio Network Utility Token</h2>
    <div className={styles['roadmap-flex']}>
      <div>
        <h4>
          RIO is a cryptocurrency and utility token for the Realio Network and
          platform.
        </h4>
      </div>
      <div className={styles['roadmap-box']}>
        <h6>RIO Utility and Roadmap</h6>
        <h6>
          <Star />
          At Network Launch
        </h6>
        <div
          className={`${styles['roadmap-list']} ${styles['roadmap-list-line']}`}
        >
          <ul>
            <li>
              <p>Delegating to Realio Network validators for block rewards</p>
            </li>
            <li>
              <p>Transaction & asset tokenization fees on the Realio Network</p>
            </li>
          </ul>
        </div>
        <h6>
          <svg
            width="42"
            height="43"
            viewBox="0 0 42 43"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M21 42.998C9.40265 42.998 -8.75885e-07 33.5961 -1.95636e-06 21.9981C-3.0367e-06 10.4014 9.40335 0.99805 21 0.998049C32.5966 0.998048 42 10.4014 42 21.998C42 33.5961 32.5973 42.998 21 42.998ZM21 4.14805C11.143 4.14805 3.15 12.1411 3.15 21.998C3.15 31.8563 11.1423 39.848 21 39.848C30.8577 39.848 38.85 31.8563 38.85 21.998C38.85 12.1411 30.857 4.14805 21 4.14805Z"
              fill="white"
            />
            <path
              d="M16.855 30.3999C16.2971 29.8395 16.2481 28.9644 16.707 28.3488L16.8598 28.1725L23.058 22.0016L16.8598 15.8265C16.2995 15.2685 16.2468 14.3936 16.7029 13.7761L16.855 13.5991C17.413 13.0387 18.2879 12.986 18.9054 13.4422L19.0824 13.5943L26.403 20.8834C26.9651 21.443 27.0162 22.3211 26.5563 22.9387L26.403 23.1156L19.0824 30.4047C18.466 31.0184 17.4688 31.0163 16.855 30.3999Z"
              fill="white"
            />
          </svg>
          Forthcoming
        </h6>
        <div className={styles['roadmap-list']}>
          <ul>
            <li>
              <p>Super Staking</p>
            </li>
            <li>
              <p>Fee Burning</p>
            </li>
            <li>
              <p>Rev Share</p>
            </li>
            <li>
              <p>Osmosis</p>
            </li>
            <li>
              <p>Multi-Chain Bridges</p>
            </li>
            <li>
              <p>Developer Outreach/Incentives</p>
            </li>
            <li>
              <p>DeFi Mortgage Market</p>
            </li>
            <li>
              <p>Blockchain Primitives</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div className={styles['roadmap-flex']}>
      <div
        className={`${styles['roadmap-flex']} ${styles['roadmap-emissions']}`}
      >
        <h3 className={styles.left}>
          RIO Supply and Block Reward Emission Schedule
        </h3>
        <img src={EmissionsImage} alt="Emission" />
      </div>
      <div className={styles.inflation}>
        <h3>RIO Genesis Supply and Inflation</h3>
        <div
          className={`${styles['roadmap-box']} ${styles['roadmap-box-table']}`}
        >
          <table>
            <tr>
              <td></td>
              <td className={styles['table-header']}>Total</td>
            </tr>
            <tr>
              <td>Supply Cap</td>
              <td>75,000,000</td>
            </tr>
            <tr>
              <td>xRIO Supply Pre-Mine</td>
              <td>45,000,000</td>
            </tr>
            <tr>
              <td>Total Initial Circulating</td>
              <td>45,000,000</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
);

export default Roadmap;
