import styles from './explore.module.css';
import Image1 from '../../images/papers.png';
import Image2 from '../../images/Vector.png';
import Image3 from '../../images/spreadsheet.png';
import Image4 from '../../images/Subtract.png';
import Image5 from '../../images/Subtract2.png';
import Image6 from '../../images/explorer.svg';
import Image7 from '../../images/audit.svg';

const Explore = () => (
  <div className={styles.explore}>
    <div className={styles['explore-text']}>
      <h4>Explore</h4>
      <h5>
        Discover resources to expand your knowledge on the Realio Network and
        get connected with the community.
      </h5>
    </div>
    <div className={styles['explore-links']}>
      <div className={styles.col}>
        <h6>Documentation</h6>
        <ul>
          <li>
            <a
              href="https://docs.realio.network"
              rel="noreferrer"
              target="_blank"
            >
              <div className={styles['explore-icon']}>
                <img src={Image1} alt="icon" />
              </div>
              <p>Documentation</p>
            </a>
          </li>
          <li>
            <a
              href="https://github.com/realiotech"
              rel="noreferrer"
              target="_blank"
            >
              <div className={styles['explore-icon']}>
                <img src={Image2} alt="icon" />
              </div>
              <p>GitHub</p>
            </a>
          </li>
          <li>
            <a
              href="https://www.realio.fund/blog"
              rel="noreferrer"
              target="_blank"
            >
              <div className={styles['explore-icon']}>
                <img src={Image3} alt="icon" />
              </div>
              <p>Blog</p>
            </a>
          </li>
          <li>
            <a
              href="https://explorer.realio.network/"
              rel="noreferrer"
              target="_blank"
            >
              <div className={styles['explore-icon']}>
                <img src={Image6} alt="icon" />
              </div>
              <p>Explorer</p>
            </a>
          </li>
          <li>
            <a
              href="https://github.com/realiotech/realio-network/blob/main/docs/audit/realio-network-audit-2023.pdf"
              rel="noreferrer"
              target="_blank"
            >
              <div className={styles['explore-icon']}>
                <img src={Image7} alt="icon" />
              </div>
              <p>Notional Audit</p>
            </a>
          </li>
        </ul>
      </div>
      <div className={styles.col}>
        <h6>Community</h6>
        <ul>
          <li>
            <a
              href="https://discord.gg/Nv9EUbRnKb"
              rel="noreferrer"
              target="_blank"
            >
              <div className={styles['explore-icon']}>
                <img src={Image4} alt="icon" />
              </div>
              <p>Discord</p>
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/realio_network"
              rel="noreferrer"
              target="_blank"
            >
              <div className={styles['explore-icon']}>
                <img src={Image5} alt="icon" />
              </div>
              <p>Twitter</p>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
);

export default Explore;
